import { h } from "preact";
import {
  Twitter,
  Instagram,
  Music,
  Facebook,
  Download,
  Mail,
} from "preact-feather";

import Player from "../../components/player";

const Home = () => {
  return (
    <>
      <div className="text-center font-thin text-white pl-2 pr-2">
        <h1 className="lg:text-6xl text-3xl pb-4 pt-8 ">CHASHE</h1>
        <p className="italic pb-2">
          ‘Chigunwe chimwe hachitswanyi inda.’
          <br />
        </p>
        <p>
          Thank you for being a part of my journey and making my dreams
          possible.
          <br />I hope to meet the deepest need of your soul with the deepest
          expression of mine.
        </p>
        <p className="pt-2">Love, Chashe</p>
      </div>
      <Player />
      <div style="background: inherit">
        <div className="text-white pt-4 text:2xl flex flex-row justify-center">
          Click
          <span className="pl-2 pr-2">
            <Download />
          </span>
          to download for free
        </div>
        <div
          className={`flex flex-row justify-center pt-4`}
          style="background: inherit;"
        >
          <a
            href="https://twitter.com/chashemusic"
            target="_blank"
            className="p-4"
          >
            <Twitter color="white" />
          </a>
          <a
            href="https://www.instagram.com/chasheofficial/"
            target="_blank"
            className="p-4"
          >
            <Instagram color="white" />
          </a>
          <a
            href="https://open.spotify.com/artist/1dvfTOafT8oFmvqKWcnU3e?si=zBgM43TsT56q3SMiFKEtEw"
            className="p-4"
            target="_blank"
          >
            <Music color="white" />
          </a>
          <a
            href="https://www.facebook.com/chashemusic/"
            target="_blank"
            className="p-4"
          >
            <Facebook color="white" />
          </a>
          <a href="mailto:chashemusic@gmail.com" className="p-4">
            <Mail color="white" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
