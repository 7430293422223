import { h, Component } from "preact";

class AmplitudeLeft extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div id="amplitude-left">
        <img data-amplitude-song-info="cover_art_url" className="album-art" />
        <div className="amplitude-visualization" id="large-visualization" />
        <div id="player-left-bottom">
          <div id="time-container">
            <span className="current-time">
              <span className="amplitude-current-minutes" />:
              <span className="amplitude-current-seconds" />
            </span>
            <div id="progress-container">
              {/* <div className="amplitude-wave-form" /> */}
              <input type="range" className="amplitude-song-slider" />
              <progress
                id="song-played-progress"
                className="amplitude-song-played-progress"
              />
              <progress
                id="song-buffered-progress"
                className="amplitude-buffered-progress"
                value="0"
              />
            </div>
            <span className="duration">
              <span className="amplitude-duration-minutes" />:
              <span className="amplitude-duration-seconds" />
            </span>
          </div>

          <div id="control-container">
            <div id="repeat-container">
              <div className="amplitude-repeat" id="repeat" />
              <div
                className="amplitude-shuffle amplitude-shuffle-off"
                id="shuffle"
              />
            </div>

            <div id="central-control-container">
              <div id="central-controls">
                <div className="amplitude-prev" id="previous" />
                <div className="amplitude-play-pause" id="play-pause" />
                <div className="amplitude-next" id="next" />
              </div>
            </div>

            <div id="volume-container">
              <div className="volume-controls">
                <div className="amplitude-mute amplitude-not-muted" />
                <input type="range" className="amplitude-volume-slider" />
                <div className="ms-range-fix" />
              </div>
              <div
                className="amplitude-shuffle amplitude-shuffle-off"
                id="shuffle-right"
              />
            </div>
          </div>

          <div id="meta-container">
            <span data-amplitude-song-info="name" className="song-name" />

            <div className="song-artist-album">
              <span data-amplitude-song-info="artist" />
              <span data-amplitude-song-info="album" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AmplitudeLeft;
