import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import Amplitude from "amplitudejs";
import { Download, Loader } from "preact-feather";
import AmplitudeLeft from "./amplitude-left";

import style from "./style";

import nowPlaying from "../../assets/player/now-playing.svg";
import songs from "../../songs.json";

const Player = () => {
  const [downloadingSongs, setDownloadingSongs] = useState([]);

  useEffect(() => {
    Amplitude.init({ songs, preload: "metadata" });
    Amplitude.setDebug(true);
    Amplitude.pause();
  }, []);

  useEffect(() => {
    window.addEventListener("online", () => {
      if (Amplitude.getSongPlayedSeconds() === 0) {
        Amplitude.getAudio().load();
        Amplitude.play();
      }
    });
  }, []);

  const downloadSong = async (e, position) => {
    e.stopPropagation();
    setDownloadingSongs((prevDownloading) => [...prevDownloading, position]);
    setTimeout(() => {
      setDownloadingSongs([]);
    }, 3000);
  };

  return (
    <div id="blue-playlist-container">
      <div id="amplitude-player">
        <AmplitudeLeft />
        <div id="amplitude-right">
          {songs.map((song, i) => (
            <>
              <div
                className="song amplitude-song-container amplitude-play-pause"
                data-amplitude-song-index={i}
              >
                <div className="song-now-playing-icon-container">
                  <div className="play-button-container" />
                  <img className="now-playing" src={nowPlaying} />
                </div>
                <div className="song-meta-data">
                  <span className="song-title">{song.name}</span>
                  <span className="song-artist">{song.artist}</span>
                </div>
                {downloadingSongs.includes(i) ? (
                  <span className={`download ${style.rotate}`}>
                    <Loader size="24" color="rgb(96, 125, 139)" />
                  </span>
                ) : (
                  <a
                    onClick={(e) => downloadSong(e, i)}
                    title={`Download ${song.name} for free`}
                    href={song.url}
                    className="download"
                    download={song.name}
                  >
                    <Download size="24" color="rgb(96, 125, 139)" />
                  </a>
                )}
                <span className="song-duration">{song.duration}</span>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Player;
